import React, { Component } from "react";
import Webcam from "react-webcam";
import { getFullFaceDescription, loadModels } from "../api/face";

const WIDTH = 600;
const HEIGHT = 400;
const inputSize = 160;

class Home extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
    this.state = {
      fullDesc: null,
      detections: null,
      faceMatcher: null,
      match: null,
      facingMode: null,
    };
  }

  componentWillMount = async () => {
    await loadModels();
    this.setInputDevice();
  };

  setInputDevice = () => {
    navigator.mediaDevices.enumerateDevices().then(async (devices) => {
      let inputDevice = await devices.filter(
        (device) => device.kind === "videoinput"
      );
      if (inputDevice.length < 2) {
        await this.setState({
          facingMode: "user",
        });
      } else {
        await this.setState({
          facingMode: {
            // exact: "environment"
            facingMode: "user",
          },
        });
      }
      this.startCapture();
    });
  };

  startCapture = () => {
    this.interval = setInterval(() => {
      this.capture();
    }, 3000);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  capture = async () => {
    if (!!this.webcam.current) {
      await getFullFaceDescription(
        this.webcam.current.getScreenshot(),
        inputSize
      ).then((fullDesc) => {
        if (!!fullDesc) {
          this.setState({
            detections: fullDesc.map((fd) => fd.detection),
          });
        }
      });
    }
  };

  render() {
    const { detections, match, facingMode } = this.state;
    let cameraStyles = null;
    let videoConstraints = null;
    if (!!facingMode) {
      videoConstraints = {
        width: WIDTH,
        height: HEIGHT,
        facingMode: facingMode,
      };
    }

    let drawBox = null;
    if (!!detections) {
      drawBox = detections.map((detection, i) => {
        let _H = detection.box.height;
        let _W = detection.box.width;
        let _X = detection.box._x;
        let _Y = detection.box._y;
        let originalSquare = HEIGHT * WIDTH;
        let zoomSquare = Math.ceil(_H * _W);

        // console.log("area quadrado: ", originalSquare);
        // console.log("area original: ", zoomSquare);
        // console.log("--------------");
        let totalPercentage = 100;
        let partialPercentage = Math.ceil(
          (totalPercentage * zoomSquare) / originalSquare
        );

        // console.log("porcentagem do total: ", partialPercentage);

        let scale = 100 - partialPercentage;

        let newScale = "scale(1." + scale + ")";

        let newWidth = WIDTH * parseFloat("1." + scale);
        let newHeight = HEIGHT * parseFloat("1." + scale);

        console.log("newWidth: ", newWidth);
        console.log("newHeight: ", newHeight);

        console.log("x: ", _X);
        console.log("y: ", _Y);

        cameraStyles = {
          transform: newScale,
        };

        // console.log("cameraStyles: ", cameraStyles);

        return (
          <div key={i}>
            <div
              style={{
                position: "absolute",
                top: "0",
                border: "solid",
                borderColor: "transparent",
                height: _H,
                width: _W,
                transform: `translate(${_X}px,${_Y}px)`,
              }}
            >
              {!!match && !!match[i] ? (
                <p
                  style={{
                    backgroundColor: "blue",
                    border: "solid",
                    borderColor: "blue",
                    width: _W,
                    marginTop: 0,
                    color: "#fff",
                    transform: `translate(-3px,${_H}px)`,
                  }}
                >
                  Your face
                </p>
              ) : null}
            </div>
          </div>
        );
      });
    }

    return (
      <div
        className="Camera"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Spotlight Webcam</h1>
        <h4>
          Help your webcam focus on people on screen. <br /> An alternative to
          Apple's Center Stage.
        </h4>
        <img
          src="https://ae.studio/img/same-day-skunkworks/watermark.png"
          alt="Same day skunkworks"
          className="watermark rotating"
          onClick={() => window.open("https://ae.studio/same-day-skunkworks")}
        />
        <div className="love">
          Made with ♥ by{" "}
          <a href="https://ae.studio" target="_blank" rel="noopener noreferrer">
            Agency Enterprise
          </a>
        </div>
        <div
          className="camera-container"
          style={{
            width: WIDTH,
            height: HEIGHT,
          }}
        >
          <div style={{ position: "relative", width: WIDTH }}>
            {!!videoConstraints ? (
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  height: HEIGHT,
                }}
              >
                <Webcam
                  audio={false}
                  width={WIDTH}
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    transition: ".3s",
                    ...cameraStyles,
                  }}
                  id="webcam_id"
                  height={HEIGHT}
                  ref={this.webcam}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
            ) : null}
            {!!drawBox ? drawBox : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
